$linkColor: #0FC6D6;
$backgroundGrey: #fafafa;
$bodyFontColor: #162F31;
$bodyFontColorSecondary: #808D8E;
$fontSize: 17px;
$borderWidth: 1px;
$borderColor: rgba(0, 0, 0, .10);
$errorColor: #a22725;
$successColor: #28944c;
$transition-duration: 0.4s;

// Tags
$tagColor: $linkColor;
$tagColorDisabled: desaturate($linkColor, 90%);
$backgroundGreyDisabled: lighten($backgroundGrey, 4%);
$tagColorActive: $linkColor;


//@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');
@import 'custom-semantic';
@import 'vendor/fontello/fontello';
@import 'vendor/mixins/mixins';


* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body {
	font-size:  $fontSize;
	line-height: 1.45455em;
	// font-weight: 100 !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
	color: $bodyFontColor;
	counter-reset: step;
	min-width: 320px !important;
	max-width: 100%;
	overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, a, p, label, select, .header, div, span {
	color: $bodyFontColor;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 400 !important;
}

h1 {
	font-size: 2rem !important;
}

h2 {
	font-size: 1.8rem !important;
}

h3 {
	font-size: 1.8rem !important;
}

a {
	cursor: pointer;
	color: $linkColor;
	font-weight : normal !important;
	
	&:hover {
		color: $linkColor;
		//text-decoration: underline;
	}
}

strong {
	font-weight: bold !important;
}

.ui.button {
	font-weight: bold !important;
	@include box-shadow(none !important);
	@include transition-property(color, border, background-color);
	@include transition-duration($transition-duration);
	
	&.basic {
		@include box-shadow(none !important);
		color: $linkColor !important;
		border: 2px solid $linkColor !important;

		&:hover {
			@include box-shadow(none !important);
			border-color: $bodyFontColor !important;
			color: $bodyFontColor !important;
		}
	}
	
	&.primary, &.positive {
		@include box-shadow(none !important);
		border: 2px solid $linkColor !important;
		background-color: $linkColor !important;
		
		&:hover {
			@include box-shadow(none !important);
			border-color: $bodyFontColor !important;
			background-color: $bodyFontColor !important;
		}
	}
	
	&:hover {
		@include box-shadow(none !important);
	}

	&.basic.red {
		@include box-shadow(none !important);
		color: $errorColor !important;
		border: 2px solid $errorColor !important;

		&:hover {
			@include box-shadow(none !important);
			border-color: $bodyFontColor !important;
			color: $bodyFontColor !important;
		}
	}
}

.ui.horizontal.list {
	font-size: inherit !important;
}

.ui.message {
	.content {
		color: inherit !important;
		font-weight: normal;
	}
	
	.icon {
		opacity: 1 !important;
	}
}

.ui.action.input {
	input:focus {
		border-color: $linkColor !important;
	}	
}

.ui.secondary.menu .item {
	border-radius: 0 !important;
}


body, .ui.input {
	input,
	input[type="color"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="email"],
	input[type="month"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="tel"],
	input[type="text"],
	input[type="time"],
	input[type="url"],
	input[type="week"],
	select:focus,
	textarea {
		color: $bodyFontColor;
		font-family: 'Quicksand', sans-serif !important;
	}
	
	textarea {
		height: 8rem !important;
	}
}

/**
 *	Spacer elements
 **/
.spacer-small {
	padding-top: 10px;
}

.spacer {
	padding-top: 15px;
}

.spacer-big {
	padding-top: 30px;
}
.spacer-large {
	padding-top: 50px;
}

p {
	&.pad-top {
		padding-top: 30px;
	}
}

hr {
	width:100%;
	border: 0;
	border-top: $borderWidth $borderColor solid;
}



/* !!! PLEASE NOTE WE ARE USING STYLED COMPONENTS NOW !!! */
