$ts-font-path: '/fonts';
@font-face {
	font-family: 'ts-icons';
	src: url('#{$ts-font-path}/ts-icons.eot?55359878');
	src: url('#{$ts-font-path}/ts-icons.eot?55359878#iefix') format('embedded-opentype'),
	url('#{$ts-font-path}/ts-icons.woff2?55359878') format('woff2'),
	url('#{$ts-font-path}/ts-icons.woff?55359878') format('woff'),
	url('#{$ts-font-path}/ts-icons.ttf?55359878') format('truetype'),
	url('#{$ts-font-path}/ts-icons.svg?55359878#ts-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "ts-icons";
	font-style: normal;
	font-weight: normal;
	font-size: 85px;
	color: $linkColor;
	speak: none;
	display: inline-block;
	//float: left;
	text-decoration: none;
	width: 1em;
	//margin-right: .2em;
	//height: 100%;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	//margin-left: .2em;
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
}

.icon-architect:before { content: '\e800'; } /* '' */
.icon-arrow-down:before { content: '\e801'; } /* '' */
.icon-arrow-left:before { content: '\e802'; } /* '' */
.icon-arrow-right:before { content: '\e803'; } /* '' */
.icon-arrow-up:before { content: '\e804'; } /* '' */
.icon-burger:before { content: '\e805'; } /* '' */
.icon-check:before { content: '\e806'; } /* '' */
.icon-close:before { content: '\e807'; } /* '' */
.icon-cloud:before { content: '\e808'; } /* '' */
.icon-designer:before { content: '\e809'; } /* '' */
.icon-display:before { content: '\e80a'; } /* '' */
.icon-everyone:before { content: '\e80b'; } /* '' */
.icon-ipad:before { content: '\e80c'; } /* '' */
.icon-pen:before { content: '\e80d'; } /* '' */
.icon-pinterest:before { content: '\e80e'; } /* '' */
.icon-play:before { content: '\e80f'; } /* '' */
.icon-search:before { content: '\e810'; } /* '' */
.icon-trash:before { content: '\e811'; } /* '' */
.icon-twitter:before { content: '\e812'; } /* '' */