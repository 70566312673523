//
// Mixins
// --------------------------------------------------


// Utilities
// -------------------------

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
@mixin clearfix() {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
}

// WebKit-style focus
@mixin tab-focus() {
	// Default
	outline: thin dotted;
	// WebKit
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
	width: $width;
	height: $height;
}
@mixin square($size) {
	@include size($size, $size);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
	&:-moz-placeholder            { color: $color; } // Firefox 4-18
	&::-moz-placeholder           { color: $color;   // Firefox 19+
		opacity: 1; } // See https://github.com/twbs/bootstrap/pull/11526
	&:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
	&::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// CSS image replacement
//
// Heads up! v3 launched with with only `.hide-text()`, but per our pattern for
// mixins being reused as classes with the same name, this doesn't hold up. As
// of v3.0.1 we have added `.text-hide()` and deprecated `.hide-text()`. Note
// that we cannot chain the mixins together in Less, so they are repeated.
//
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757

// Deprecated as of v3.0.1 (will be removed in v4)
@mixin hide-text() {
	font: #{0/0} a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
// New mixin to use as of v3.0.1
@mixin text-hide() {
	@include hide-text();
}



// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
//   supported browsers that have box shadow capabilities now support the
//   standard `box-shadow` property.
@mixin box-shadow($shadow...) {
	-webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
	box-shadow: $shadow;
}

// Transitions
@mixin transition($transition...) {
	-webkit-transition: $transition;
	transition: $transition;
}
@mixin transition-property($transition-property...) {
	-webkit-transition-property: $transition-property;
	transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
	-webkit-transition-duration: $transition-duration;
	transition-duration: $transition-duration;
}
@mixin transition-transform($transition...) {
	-webkit-transition: -webkit-transform $transition;
	-moz-transition: -moz-transform $transition;
	-o-transition: -o-transform $transition;
	transition: transform $transition;
}

// Transformations
@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-ms-transform: rotate($degrees); // IE9 only
	transform: rotate($degrees);
}
@mixin scale($scale-args...) {
	-webkit-transform: scale($scale-args);
	-ms-transform: scale($scale-args); // IE9 only
	transform: scale($scale-args);
}
@mixin translate($x, $y) {
	-webkit-transform: translate($x, $y);
	-ms-transform: translate($x, $y); // IE9 only
	transform: translate($x, $y);
}
@mixin skew($x, $y) {
	-webkit-transform: skew($x, $y);
	-ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
	transform: skew($x, $y);
}
@mixin translate3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

@mixin rotateX($degrees) {
	-webkit-transform: rotateX($degrees);
	-ms-transform: rotateX($degrees); // IE9 only
	transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
	-webkit-transform: rotateY($degrees);
	-ms-transform: rotateY($degrees); // IE9 only
	transform: rotateY($degrees);
}
@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
	-moz-perspective: $perspective;
	perspective: $perspective;
}
@mixin perspective-origin($perspective) {
	-webkit-perspective-origin: $perspective;
	-moz-perspective-origin: $perspective;
	perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin; // IE9 only
	transform-origin: $origin;
}

// Animations
@mixin animation($animation) {
	-webkit-animation: $animation;
	animation: $animation;
}
@mixin animation-name($name) {
	-webkit-animation-name: $name;
	animation-name: $name;
}
@mixin animation-duration($duration) {
	-webkit-animation-duration: $duration;
	animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
	-webkit-animation-timing-function: $timing-function;
	animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
	-webkit-animation-delay: $delay;
	animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
	-webkit-animation-iteration-count: $iteration-count;
	animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
	-webkit-animation-direction: $direction;
	animation-direction: $direction;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
@mixin backface-visibility($visibility){
	-webkit-backface-visibility: $visibility;
	-moz-backface-visibility: $visibility;
	backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
	-webkit-box-sizing: $boxmodel;
	-moz-box-sizing: $boxmodel;
	box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
	-webkit-user-select: $select;
	-moz-user-select: $select;
	-ms-user-select: $select; // IE10+
	-o-user-select: $select;
	user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
	resize: $direction; // Options: horizontal, vertical, both
	overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
	-webkit-column-count: $column-count;
	-moz-column-count: $column-count;
	column-count: $column-count;
	-webkit-column-gap: $column-gap;
	-moz-column-gap: $column-gap;
	column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
	word-wrap: break-word;
	-webkit-hyphens: $mode;
	-moz-hyphens: $mode;
	-ms-hyphens: $mode; // IE10+
	-o-hyphens: $mode;
	hyphens: $mode;
}

// Opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	// IE8 filter
	$opacity-ie: ($opacity * 100);
	filter: #{alpha(opacity=$opacity-ie)};
}

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.
@mixin reset-filter() {
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}



// Retina images
//
// Short retina mixin for setting background-image and -size

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
	background-image: url(if($bootstrap-sass-asset-helper, twbs-image-path("#{$file-1x}"), "#{$file-1x}"));

	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		background-image: url(if($bootstrap-sass-asset-helper, twbs-image-path("#{$file-2x}"), "#{$file-2x}"));
		background-size: $width-1x $height-1x;
	}
}


// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
	display: $display;
	max-width: 100%; // Part 1: Set a maximum relative to the parent
	height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}



