
@import "../../common.scss";

/* !!! PLEASE NOTE WE ARE USING STYLED COMPONENTS NOW !!! */

@keyframes fadeIn {
	from {
		opacity:0;
		visibility:hidden;
	}
	to {
		opacity:1;
		visibility:visible;
	}
}

@keyframes fadeOut {
	from {
		opacity:1;
		visibility:visible;
	}
	to {
		opacity:0;
		visibility:hidden;
	}
}

.container {
	width: 100%;
}


.ui.menu .item {
	padding-left:0;
	padding-right:0;
	margin-left:0;
	margin-right:0;
}



// this is a special dark place :P
.sketch-list-sketch-description-wrapper {
    z-index: 10000000;
    display: block;
	border: 10px red solid;
    position: fixed;
    bottom: 0;
    left: -100%;
}
.sketch-list-sketch-description {
    color: #888;
	font-weight: 400 !important;
	word-wrap: break-word;	
}

/* !!! PLEASE NOTE WE ARE USING STYLED COMPONENTS NOW !!! */
